const state = {
  jobs: [],
  job: null,
  totalJobsCount: 0
};

import Axios from 'axios';

const getters = {};

const actions = {
  loadJobs(context) {
    context.commit('parseJsonWithJobs');
  },
  retrieveJobs: async (context, payload) => {
    let page = payload.page || 1;
    let {data: data} = await Axios.get(`/jobs.json?page=${page}${location.search}`);
    let jobsData = document.getElementById("jobsList");
    jobsData.setAttribute("data-jobs", JSON.stringify(data));
    context.commit('parseJsonWithJobs');
  }
};

const mutations = {
  parseJsonWithJobs(state) {
    let jobsData = document.getElementById("jobsList");
    let jobs = jobsData ? JSON.parse(jobsData.getAttribute("data-jobs")).data : null;

    let jobData = document.getElementById("jobPage");
    let job = jobData ? JSON.parse(jobData.getAttribute("data-job")).data : null;

    let jobCountData = document.getElementById("jobsCount");
    let jobsCount = jobCountData ? JSON.parse(jobCountData.getAttribute("data-jobs-count")) : 0;

    state.jobs = jobs;
    state.job = job;
    state.totalJobsCount = jobsCount;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
