import axios from 'axios';

let create = function(job_id) {
  let params = {
    job_application: {job_id: job_id}
  };

  return axios.post('/job_applications', params);
};

export default {
  create: create
};
