export default {
    translation: {
        wholeVacanciesList: 'Whole vacancies list',
        respondViaJooby: 'Respond via Jooby.dev',
        respondViaTelegram: 'Respond via Telegram',
        respondViaWebsite: 'Respond via website',
        contact: 'Contact',
        email: 'Email',
        telegram: 'Telegram',
        buttons: {
            example: 'Example',
            submit: 'Submit request',
        },
        channels: {
            python: 'Python Jobs',
            js: 'JS Jobs',
            golang: 'Golang Jobs',
            qa: 'QA Jobs',
            ruby: 'Ruby & Elixir Jobs',
            java: 'Java Jobs',
            devops: 'DevOps Jobs',
            android: 'Android Jobs',
            ios: 'iOS Jobs',
            rust: 'Rust Jobs',
        },
        workTypes: {
            remote: 'Remote',
            office: 'Office',
            officeRemote: 'Office/remote',
        },
        employmentType: {
            fullTime: 'Full-time',
            partTime: 'Part-time',
        },
        salary: {
            paymentType: 'Payment type',
            fork: 'Salary range',
            gross: 'gross',
            netto: 'netto',
        },
        footer: {
            products: 'Ecosystem products',
            ITmarketAnalytics: 'IT market analytics',
            lookingForJob: 'Let\'s looking for job together!',
            findOutAboutJooby: 'Find out about Jooby',
            aboutUs: 'About us',
            termsOfUse: 'Terms of use',
        },
        modals: {
            thanksForResponse: 'Thanks for response',
            employeeContact: 'Employee contact:',
            sadOk: 'Okay :(',
            vacancyWasSended: 'Vacancy was sended',
        },
        searchForm: {
            salary: {
                label: 'Range:',
                any: 'Any',
                before1000Usd: '0 - 1000 USD',
                from1000to3000Usd: '1000 - 3000 USD',
                from2000to4000Usd: '2000 - 4000 USD',
                from3000to5000Usd: '3000 - 5000 USD',
                from5000Usd: '5000+ USD',
            },
            specializations: {
                label: 'Specializations:',
                all: 'All',
                ruby: 'Ruby engineer',
                go: 'Go engineer',
                js: 'Javascript engineer',
                python: 'Python engineer',
            },
            period: {
                label: 'Period:',
                all: 'For all time',
                monthly: 'For the month',
                weekly: 'For the week',
            },
            justCall: 'just call!',
            advancedSearch: 'Advanced search',
            nothingFound: 'We found nothing',
            letClickButton: 'Let\'s click on button!',
            findBestJob: 'Find the best job!',
        },
        vacancyForm: {
            title: 'Filling in the vacancy form',
            infoFilling: 'Filling in basic information about the vacancy',
            feedback: {
                textareaConstraints: 'The field must contain between 10 and 2000 characters',
                tgInputConstraints: 'The field must contain at least 5 characters and consist of a-z, 0-9, _',
            },
            selectChannel: 'Select channel',
            selectWorkType: 'Select work type',
            selectEmploymentType: 'Select employment type',
            companyDescription: {
                title: 'About company',
                mainAdvice: 'Write a little about the company: name, type, what they do and who they work with, as well as the website.',
                cultureAdvice: 'You can specify the company culture - that is welcome!',
                example: {
                    aboutCompany: 'Sophistica - a small outsourcing company, custom software development, 29 years on the IT services market. We work with wel-known clients such as Deutsche Bahn, Merck, Commerzbank, Dusseldorf Airport, Wiener Linier, Swiss Accident Insurance, DHL, BASF and others. We have offices in the UK, Estonia and Serbia.',
                    aboutTeam: 'We have a team of 50 people, all between 20 and 40 years old, we travel, enjoy life and are constantly developing professionally. We do not have deadlines, meetings and regular reports and other bureaucracy. We value working comfort.',
                    website: 'https://sophistica.com/',
                },
            },
            jobDescription: {
                title: 'Who are we looking for?',
                employeeAdvice: 'Write about the specialist level, tasks and responsibilities.',
                projectAdvice: 'This also describes the project where the specialist will be working on and the technology stack',
                example: {
                    aboutEmployee: 'Due to growing tasks we are looking for a Senior Ruby on Rails Backend Developer. We have many interesting shuffles and a very strong team from whom you can learn and a relatively free work schedule.',
                    aboutProject: 'Project: development of universal software for home care management for a U.S. customer.',
                    coreTasks: 'Main tasks:',
                    task1: '• Develop new features, solve real problems of system users;',
                    task2: '• Reduce technical debt, find places for refactoring and implement it;',
                    task3: '• Suggest new cool ideas and promote them.',
                    stack: 'Stack: Ruby, Rails, Redis(SSDB), Clickhouse, Ansible, Capistrano, Gitlab, Code review, CI/CD, many tests, a loaded component in Golang (geo-distributed).',
                },
            },
            requirements: {
                title: 'Candidate requirements',
                mainAdvice: 'Describe the requirements of the job: exactly the skills without which the candidate will not be able to live in your project',
                example: {
                    req1: '• Ruby on Rails - experience in commercial development from 3 years;',
                    req2: '• Strong knowledge of computer science: data structures, complexity of algorithms, working with memory, etc.',
                    req3: '• Understanding of the principles of databases (MySQL/PostgreSQL);',
                    req4: '• Experience in writing automated tests using RSpec and Capybara;',
                    req5: '• English level at least B2.',
                    willBeAdvantage: 'It will be a plus: experience with one of the modern frontend frameworks (React or Vue).',
                },
            },
            workingConditions: {
                title: 'Working conditions and benefits',
                mainAdvice: 'Here write about the type of registration (staffing, IE, self-employment, etc.), probation period, working hours, location of offices, availability of insurance, educational courses and conferences, bonuses.',
                relocationAdvice: 'You can also specify here about the possibility of relocation and getting relocation assistance',
                example: {
                    employment: '• Registration - self-employment, IE;',
                    probationPeriod: '• Paid probation period of 3 months;',
                    residence: 'We accept residents of any country',
                    workingSchedule: '• Flexible schedule, work around the Moscow time zone, the most important thing - to cross with their team at work time;',
                    tracking: '• Lack of tracking systems. Working in Slack, Jira, GitLab;',
                    salaryReview: '• Salary review every six months;',
                    healthInsurance: '• Health insurance (after probation period);',
                    languageCourses: '• English lessons (after probation period);',
                    device: '• We give out a corporate MacBook Pro (on request);',
                    office: '• There are offices in Saint-Petersburg and Tallinn',
                    relocation: '• Relocation to Tallinn, Estonia + relocation package',
                },
            },
            salaryFork: {
                mainAdvice: 'Specify the amount and currency (RUB, EUR, USD)',
                example: 'Example: 6000 - 8500 USD',
            },
            contacts: {
                example: {
                    tg: 'Correct Telegam format: just username (not a link) without sign @',
                },
            },
            placeholders: {
                email: 'Your email',
                tg: 'Your telegram',
            },
            supportDescription: {
                aboutFormReset: "* after submitting the form is not resetting - thus it works so that you can fill another jobs faster.",
                aboutAdditionalQuestions: 'Please contact us if you have any questions:',
                linkText: 'Click!'
            }
        },
    },
};
