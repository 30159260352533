<template>
  <modal name="errorOccured" height="auto" :scrollable=true @before-open="beforeOpen">
    <b-card-header>
      <b-card-title>{{this.title}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <h4>{{this.text}}</h4>
    </b-card-body>
    <b-card-footer>
      <b-button type="submit" variant="primary">{{ $t('modals.sadOk')}}</b-button>
    </b-card-footer>
  </modal>
</template>

<script>
export default {
  methods: {
      beforeOpen (event) {
        this.title = event.params.title;
        this.text = event.params.text;
        this.buttons = event.params.buttons;
      }
    },
    data: () => {
      return {
        title: 'Error occured',
        text: '',
      }
    }
  }
</script>
