
// initial state

import axios from "axios";

const state = {
  candidate: null,
  recruiter: null,
  token: null
};

const getters = {
  authorized: (state, getters) => {
    return state.token != null;
  },
  user: (state, getters) => {
    return {
      authorized: state.token != null,
      candidate: state.candidate,
      recruiter: state.recruiter
    };
  }
};

const actions = {
  loadToken(context) {
    context.commit('parseTokenFromPage')
  },
  async fetchContact(context, payload) {
    let job_id = payload.job_id;
    return await axios.post("/contacts.json", {contact: {job_id: job_id}})
  },
  async fetchExternalContact(context, payload) {
    let job_id = payload.job_id;
    return await axios.post("/contacts.json", {contact: {job_id: job_id, external: true}})
  }
};

const mutations = {
  parseTokenFromPage(state) {
    let tokenElement = document.getElementById("userToken");
    state.token = tokenElement ? tokenElement.getAttribute("value") : null;
    axios.defaults.headers.common.Authorization = `Bearer ${state.token}`;
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common.Accept = 'application/json';
  },
  showContactModal(state, payload) {
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
