<template v-if="ok">
<div>
  <b-list-group>
    <job v-for="job in this.jobInfos" v-bind:key="job.id" v-bind:job="job"></job>
  </b-list-group>
    <b-pagination v-if="this.check_for_main_page()"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      v-on:input="this.fetch_page"
    ></b-pagination>
  <div class="full-jobs-container" v-else>
    <h2 class="text-center">
      <a class="btn btn-yellow btn-dark expand-jobs" href="https://jooby.dev/jobs?utm_source=main_jooby_page">{{ $t('wholeVacanciesList') }}</a>
    </h2>
  </div>
</div>
</template>

<script>
  import Job from 'job.vue'
  import { BPagination } from 'bootstrap-vue'
  import { mapState } from 'vuex';

  export default {
    components: {Job, BPagination},
    computed: {
      ...mapState({
        jobs: state => state.jobsData.jobs,
        totalJobsCount: state => state.jobsData.totalJobsCount
      }),
    rows() {
      return this.totalJobsCount;
    },
    jobInfos() {
      return this.jobs.map(jobInfo => jobInfo);
    }
  },
    methods: {
      fetch_page: function(e) {
        this.$store.dispatch('jobsData/retrieveJobs', {page: e});
      },
      check_for_main_page: function (e) {
        return window.location.pathname != '/'
      },
    },
    data() {
      return {
        perPage: 5,
        currentPage: 1
      }
    },
  }
</script>
