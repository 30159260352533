<template>
  <modal name="successSubmitVacancyForm" class="success-vacancy-modal" height="auto" :adaptive=true  :scrollable=true>
    <b-card-header>
      <b-card-title>{{ $t('modals.vacancyWasSended') }} &nbsp;🐾&nbsp; 🐕</b-card-title>
    </b-card-header>
  </modal>
</template>
<script>

export default {}
</script>
