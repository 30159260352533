<template>
  <div class="jooby-svg" title="ГАВ!">
    <svg version="1.0"
      width="20.000000pt" height="20.000000pt" viewBox="0 0 314.000000 270.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,270.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M2065 2688 c-2 -7 -6 -38 -10 -68 -3 -30 -8 -79 -11 -107 -3 -29 -10
      -53 -15 -53 -5 0 -9 -18 -9 -39 0 -22 -4 -43 -10 -46 -5 -3 -10 -22 -10 -41 0
      -19 -4 -34 -10 -34 -5 0 -10 -13 -10 -29 0 -17 -4 -33 -10 -36 -5 -3 -10 -19
      -10 -35 0 -16 -4 -32 -10 -35 -5 -3 -10 -17 -10 -30 0 -13 -4 -27 -10 -30 -5
      -3 -10 -19 -10 -36 0 -16 -4 -29 -10 -29 -5 0 -10 -15 -10 -34 0 -19 -4 -38
      -10 -41 -5 -3 -10 -13 -10 -22 0 -20 64 -83 84 -83 9 0 16 -3 16 -7 0 -5 10
      -13 23 -19 60 -30 112 -60 115 -66 2 -5 12 -8 23 -8 10 0 19 -4 19 -10 0 -5 9
      -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10
      20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 20
      -10 11 0 20 -4 20 -10 0 -5 7 -10 15 -10 8 0 24 -5 34 -12 15 -9 23 -9 35 1
      13 11 16 37 16 142 l0 129 89 0 c50 0 93 5 96 10 3 6 22 10 41 10 19 0 34 5
      34 10 0 6 7 10 15 10 19 0 55 35 74 73 8 15 18 27 23 27 4 0 8 9 8 20 0 11 4
      20 8 20 10 0 76 129 95 186 10 30 11 45 3 55 -14 17 -93 59 -112 59 -8 0 -14
      5 -14 10 0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -3 6 -17 10 -31 10 -13 0 -24 4
      -24 8 0 4 -21 14 -47 21 -27 8 -55 18 -63 21 -29 14 -87 30 -108 30 -12 0 -22
      4 -22 9 0 5 -24 12 -52 15 -138 14 -136 13 -221 97 -63 62 -86 79 -109 79 -16
      0 -30 -6 -33 -12z"/>
      <path d="M315 1987 c-33 -42 -48 -107 -25 -107 6 0 10 -7 10 -16 0 -9 8 -24
      18 -33 10 -9 29 -32 42 -51 13 -19 40 -51 60 -70 19 -19 40 -46 47 -60 9 -18
      14 -173 18 -580 9 -928 12 -990 60 -990 7 0 15 -5 17 -11 2 -6 33 -11 74 -12
      64 -1 74 2 97 26 15 14 27 33 27 42 0 8 4 15 9 15 16 0 22 112 27 483 l5 359
      492 -6 c597 -8 715 -13 733 -31 11 -11 14 -69 14 -282 0 -147 5 -329 12 -403
      l11 -135 35 -32 c20 -18 42 -33 49 -33 7 0 13 -4 13 -10 0 -13 67 -13 75 0 3
      5 13 10 22 10 9 0 23 8 32 18 9 9 28 23 43 31 l27 14 5 401 c2 221 9 433 15
      471 6 39 13 91 17 118 3 26 10 47 14 47 13 0 35 180 26 214 -8 30 -59 86 -80
      86 -9 0 -16 4 -16 8 0 4 -22 19 -50 33 -27 13 -50 27 -50 32 0 4 -9 7 -20 7
      -11 0 -20 5 -20 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -9 10 -20 10 -11
      0 -20 5 -20 10 0 6 -9 10 -19 10 -11 0 -21 3 -23 8 -4 10 -290 152 -305 152
      -7 0 -13 3 -13 8 0 29 -62 32 -591 32 l-537 0 -38 38 c-22 20 -55 48 -74 62
      -19 13 -42 31 -50 40 -24 25 -96 60 -121 60 -13 -1 -28 -6 -34 -13z"/>
      <path d="M896 865 c-8 -21 3 -232 14 -275 26 -103 35 -130 42 -130 4 0 8 -6 8
      -13 0 -14 32 -87 56 -127 8 -14 22 -42 32 -62 9 -21 20 -38 24 -38 5 0 8 -6 8
      -12 0 -22 78 -112 133 -155 19 -15 87 -17 87 -3 0 6 7 10 15 10 17 0 53 33 73
      68 14 26 16 92 2 92 -5 0 -10 11 -10 24 0 14 -4 28 -10 31 -5 3 -10 15 -10 26
      0 10 -4 19 -10 19 -5 0 -10 7 -10 15 0 8 -4 23 -9 33 -42 82 -71 147 -71 158
      0 8 -4 14 -10 14 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11
      -4 20 -10 20 -5 0 -10 9 -10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 -3
      20 -8 20 -4 0 -15 18 -24 39 -22 51 -89 121 -116 121 -11 0 -24 5 -27 10 -10
      17 -102 12 -109 -5z"/>
      </g>
    </svg>
  </div>  
</template>

<script>
    export default {
      data() {
          return { }
      },
    }
</script>