<template>
  <div class="mx-5 my-5">
    <div class="text-center">
      <p class="display-6 fw-normal">{{ $t('vacancyForm.title') }}</p>
    </div>

    <form class="needs-validation" method="post" v-on:submit="submitForm">
      <fieldset>
        <legend class="visually-hidden">{{ $t('vacancyForm.infoFilling') }}</legend>
        <div class="row mb-3">
          <div class="col col-sm-8 col-md-6 col-lg-4">
            <label for="selectChannel" class="form-label h6">{{ $t('vacancyForm.selectChannel') }}</label>
            <select name="channels" class="form-select" v-model="selectChannel" required>
              <option value="pythonJobs">{{ $t('channels.python') }}</option>
              <option value="jsJobs">{{ $t('channels.js') }}</option>
              <option value="goLangJobs">{{ $t('channels.golang') }}</option>
              <option value="qaJobs">{{ $t('channels.qa') }}</option>
              <option value="rubyJobs" selected>{{ $t('channels.ruby') }}</option>
              <option value="javaJobs">{{ $t('channels.java') }}</option>
              <option value="devOpsJobs">{{ $t('channels.devops') }}</option>
              <option value="androidJobs">{{ $t('channels.android') }}</option>
              <option value="iOsJobs">{{ $t('channels.ios') }}</option>
              <option value="rustJobs">{{ $t('channels.rust') }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-sm-8 col-md-6 col-lg-4">
            <label for="selectWorkType" class="form-label h6">{{ $t('vacancyForm.selectWorkType') }}</label>
            <select name="type-of-work" class="form-select" v-model="selectWorkType" required>
              <option value="remote">{{ $t('workTypes.remote') }}</option>
              <option value="office">{{ $t('workTypes.office') }}</option>
              <option value="office-remote">{{ $t('workTypes.officeRemote') }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-sm-8 col-md-6 col-lg-4">
            <label for="selectEmploymentType" class="form-label h6">{{ $t('vacancyForm.selectEmploymentType') }}</label>
            <select name="type-of-employment" class="form-select" v-model="selectEmploymentType" required>
              <option value="full-time">{{ $t('employmentType.fullTime') }}</option>
              <option value="part-time">{{ $t('employmentType.partTime') }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-lg-8">
            <label for="companyDescription" class="form-label h6">{{ $t('vacancyForm.companyDescription.title') }}</label>
            <div class="d-flex justify-content-between mb-2">
              <div class="align-self-end">
                <div class="small">
                  {{ $t('vacancyForm.companyDescription.mainAdvice') }}
                </div>
                <div class="small">
                  {{ $t('vacancyForm.companyDescription.cultureAdvice') }}
                </div>
              </div>
              <div class="align-self-end border-0">
                <b-button class="btn btn-sm" id="companyHelpBtn" variant="light">{{ $t('buttons.example') }}</b-button>
                <b-popover target="companyHelpBtn" triggers="hover" placement="rightbottom">
                  <template #title>{{ $t('vacancyForm.companyDescription.title') }}</template>
                  <p>{{ $t('vacancyForm.companyDescription.example.aboutCompany') }}</p>
                  <p>{{ $t('vacancyForm.companyDescription.example.aboutTeam') }}</p>
                  <a>{{ $t('vacancyForm.companyDescription.example.website') }}</a>
                </b-popover>
              </div>
            </div>
            <textarea class="form-control" v-model="companyDescription" minlength="10" maxlength="3000" style="height: 300px" required></textarea>
            <div class="invalid-feedback">{{ $t('vacancyForm.feedback.textareaConstraints') }}</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-lg-8">
            <label for="jobDescription" class="form-label h6">{{ $t('vacancyForm.jobDescription.title') }}</label>
            <div class="d-flex justify-content-between mb-2">
              <div>
                <div class="small">{{ $t('vacancyForm.jobDescription.employeeAdvice') }}</div>
                <div class="small">{{ $t('vacancyForm.jobDescription.projectAdvice') }}</div>
              </div>
              <div class="align-self-end border-0">
                <b-button class="btn btn-sm" id="jobDescriptionHelpBtn" variant="light">{{ $t('buttons.example') }}</b-button>
                <b-popover target="jobDescriptionHelpBtn" triggers="hover" placement="rightbottom">
                  <template #title>{{ $t('vacancyForm.jobDescription.title') }}</template>
                  <p>{{ $t('vacancyForm.jobDescription.example.aboutEmployee') }}</p>
                  <p>{{ $t('vacancyForm.jobDescription.example.aboutProject') }}</p>
                  <span>{{ $t('vacancyForm.jobDescription.example.coreTasks') }}</span>
                  <div class="ps-2">{{ $t('vacancyForm.jobDescription.example.task1') }}</div>
                  <div class="ps-2">{{ $t('vacancyForm.jobDescription.example.task2') }}</div>
                  <div class="ps-2">{{ $t('vacancyForm.jobDescription.example.task3') }}</div>
                  <div class="mt-4">{{ $t('vacancyForm.jobDescription.example.stack') }}</div>
                </b-popover>
              </div>
            </div>
            <textarea class="form-control" v-model="jobDescription" minlength="10" maxlength="3000" style="height: 300px" required></textarea>
            <div class="invalid-feedback">{{ $t('vacancyForm.feedback.textareaConstraints') }}</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-lg-8">
            <label for="requirements" class="form-label h6">{{ $t('vacancyForm.requirements.title') }}</label>
            <div class="d-flex justify-content-between mb-2">
              <div class="align-self-center">
                <div class="small">{{ $t('vacancyForm.requirements.mainAdvice') }}</div>
              </div>
              <div class="align-self-end border-0">
                <b-button class="btn btn-sm" id="requirementsHelpBtn" variant="light">{{ $t('buttons.example') }}</b-button>
                <b-popover target="requirementsHelpBtn" triggers="hover" placement="rightbottom">
                  <template #title>{{ $t('vacancyForm.requirements.title') }}</template>
                  <div>{{ $t('vacancyForm.requirements.example.req1') }}</div>
                  <div>{{ $t('vacancyForm.requirements.example.req2') }}</div>
                  <div>{{ $t('vacancyForm.requirements.example.req3') }}</div>
                  <div>{{ $t('vacancyForm.requirements.example.req4') }}</div>
                  <div>{{ $t('vacancyForm.requirements.example.req5') }}</div>
                  <div class="mt-4">{{ $t('vacancyForm.requirements.example.willBeAdvantage') }}</div>
                </b-popover>
              </div>
            </div>
            <textarea class="form-control" v-model="requirementsDescription" minlength="10" maxlength="3000" style="height: 300px" required></textarea>
            <div class="invalid-feedback">{{ $t('vacancyForm.feedback.textareaConstraints') }}</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-lg-8">
            <label for="workingConditions" class="form-label h6">{{ $t('vacancyForm.workingConditions.title') }}</label>
            <div class="d-flex justify-content-between mb-2">
              <div>
                <div class="small">{{ $t('vacancyForm.workingConditions.mainAdvice') }}</div>
                <div class="small">{{ $t('vacancyForm.workingConditions.relocationAdvice') }}</div>
              </div>
              <div class="align-self-end border-0">
                <b-button class="btn btn-sm" id="workingConditionsHelpBtn" variant="light">{{ $t('buttons.example') }}</b-button>
                <b-popover target="workingConditionsHelpBtn" triggers="hover" placement="rightbottom">
                  <template #title>{{ $t('vacancyForm.workingConditions.title') }}</template>
                  <div>{{ $t('vacancyForm.workingConditions.example.employment') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.probationPeriod') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.residence') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.workingSchedule') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.tracking') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.salaryReview') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.healthInsurance') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.languageCourses') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.device') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.office') }}</div>
                  <div>{{ $t('vacancyForm.workingConditions.example.relocation') }}</div>
                </b-popover>
              </div>
            </div>
            <textarea class="form-control" v-model="workingConditions" minlength="10" maxlength="3000" style="height: 300px" required></textarea>
            <div class="invalid-feedback">{{ $t('vacancyForm.feedback.textareaConstraints') }}</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col col-md-6">
            <label for="salaryFork" class="form-label h6">{{ $t('salary.fork') }}</label>
            <div class="small mb-2">
              {{ $t('vacancyForm.salaryFork.mainAdvice') }}
            </div>
            <input type="text" class="form-control" v-model="salaryFork" placeholder="" minlength="5" maxlength="70" required
                   aria-describedby="salaryExample">
            <div id="salaryExample" class="form-text">{{ $t('vacancyForm.salaryFork.example') }}</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6 col-sm-5 col-md-4 col-lg-3">
            <label for="selectPaymentType" class="form-label h6">{{ $t('salary.paymentType') }}</label>
            <select name="type-of-payment" class="form-select" id="selectPaymentType" v-model="selectPaymentType" required>
              <option value="gross">{{ $t('salary.gross') }}</option>
              <option value="net" selected>{{ $t('salary.netto') }}</option>
            </select>
          </div>
        </div>
      </fieldset>
      <fieldset class="pt-2">
        <legend class="mb-0">
          {{ $t('contact') }}
          <div id="contactsHelpBtn" class="d-inline">
            <b-icon-patch-question style="width: 0.75em" />
            <b-popover target="contactsHelpBtn" triggers="hover" placement="right">
              <div>{{ $t('vacancyForm.contacts.example.tg') }}</div>
            </b-popover>
          </div>
        </legend>
        <div class="row g-3 mb-3">
          <div class="col-12 col-sm-8 col-md-6 col-lg-4">
            <label for="email" class="form-label visually-hidden">{{ $t('Email') }}</label>
            <input type="email" class="form-control" id="email" :placeholder="$t('email')" minlength="5" maxlength="256" v-model="email"
                   required>
          </div>
          <input class="form-control" style="width: 0.01px; height: 0px; padding: 0; margin: 0" tabindex="-1" placeholder="sample@email.com" minlength="5" maxlength="50">
          <div class="col-12 col-sm-8 col-md-6 col-lg-4 mb-3">
            <label for="tgUsername" class="form-label visually-hidden">{{ $t('Telegram') }}</label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">@</span>
              <input type="text" class="form-control" id="tgUsername" :placeholder="$t('telegram')" v-model="tgUsername"
                     pattern="\w{5,32}" aria-describedby="basic-addon1" required>
              <div class="invalid-feedback ps-5">{{ $t('vacancyForm.feedback.tgInputConstraints') }}</div>
            </div>
          </div>
        </div>
      </fieldset>

      <success-submit-vacancy-form></success-submit-vacancy-form>
      <div class="row mb-3">
        <div class="col text-center text-sm-start">
          <button type="submit" :disabled="isDisabled" class="btn btn-outline btn-yellow btn-dark">{{ $t('buttons.submit') }}</button>
        </div>
      </div>
    </form>

    <div class="support-desc">
      <div class="small text-muted">{{ $t('vacancyForm.supportDescription.aboutFormReset') }}</div>
    </div>

    <div class="text-success">
      <hr>
    </div>

    <div class="support-desc">
      <div>
        {{ $t('vacancyForm.supportDescription.aboutAdditionalQuestions') }}
        <a href="https://t.me/jooby_admin">{{ $t('vacancyForm.supportDescription.linkText')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconPatchQuestion } from 'bootstrap-vue';
import successSubmitVacancyForm from './src/components/modals/successSubmitVacancyForm';

export default {
  components: {
    successSubmitVacancyForm,
    BIconPatchQuestion,
  },
  data: function() {
    return {
      selectChannel: 'rubyJobs',
      selectWorkType: 'remote',
      selectPaymentType: 'net',
      companyDescription: '',
      selectEmploymentType: 'full-time',
      jobDescription: '',
      requirementsDescription: '',
      workingConditions: '',
      salaryFork: '',
      salaryType: '',
      email: '',
      tgUsername: '',
      isSubmitDisabled: false,
    }
  },
  computed: {
    isDisabled() {
      return this.isSubmitDisabled;
    }
  },
  methods: {
    disableButton() {
      this.isSubmitDisabled = true;
      setTimeout(() => {
        this.isSubmitDisabled = false;
      }, 7000);
    },
    submitForm(event) {
      event.preventDefault();
      const formData = new FormData();
      const attr = JSON.parse(JSON.stringify(this.$data));
      Object.entries(attr).forEach(([key, value]) => formData.append(key, value));

      this.disableButton();
      fetch('/vacancy_forms', {
        method: 'POST',
        body: formData,
      }).then(() => {
        this.$modal.show('successSubmitVacancyForm');
        setTimeout(() => {
          this.$modal.hide('successSubmitVacancyForm');
        }, 3200);
      });
    },
  },
};
</script>
