<template>
  <div class="settings-search">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <animateTransform attributeName="transform" begin="mouseover" dur="5s" end="mouseout" from="0 0 0" repeatCount="indefinite" to="360 0 0" type="rotate"/>
      <path d="m18.902344 7.828125-1.566406-.199219c-.128907-.398437-.289063-.78125-.472657-1.144531l.964844-1.246094c.394531-.503906.347656-1.214843-.101563-1.648437l-1.3125-1.3125c-.4375-.453125-1.148437-.496094-1.652343-.105469l-1.246094.964844c-.363281-.183594-.746094-.34375-1.144531-.472657l-.199219-1.5625C12.097656.472656 11.5625 0 10.933594 0H9.066406C8.4375 0 7.902344.472656 7.828125 1.097656l-.199219 1.566406c-.398437.128907-.78125.289063-1.144531.472657l-1.246094-.964844c-.503906-.390625-1.214843-.347656-1.648437.101563l-1.3125 1.3125c-.453125.4375-.5 1.148437-.105469 1.652343l.964844 1.246094c-.183594.363281-.34375.746094-.472657 1.144531l-1.5625.199219C.472656 7.902344 0 8.4375 0 9.066406v1.867188c0 .628906.472656 1.164062 1.097656 1.238281l1.566406.199219c.128907.398437.289063.78125.472657 1.144531l-.964844 1.246094c-.394531.503906-.347656 1.214843.101563 1.648437l1.3125 1.3125c.4375.453125 1.148437.496094 1.652343.105469l1.246094-.96875c.363281.1875.746094.347656 1.144531.476563l.199219 1.5625C7.902344 19.527344 8.4375 20 9.066406 20h1.867188c.628906 0 1.164062-.472656 1.238281-1.097656l.199219-1.566406c.398437-.128907.78125-.289063 1.144531-.472657l1.246094.964844c.503906.394531 1.214843.347656 1.648437-.101563l1.3125-1.3125c.453125-.4375.5-1.148437.105469-1.652343l-.964844-1.246094c.183594-.363281.34375-.746094.472657-1.144531l1.5625-.199219C19.527344 12.097656 20 11.5625 20 10.933594V9.066406c0-.628906-.472656-1.164062-1.097656-1.238281ZM10 14.167969c-2.296875 0-4.167969-1.871094-4.167969-4.167969S7.703125 5.832031 10 5.832031 14.167969 7.703125 14.167969 10 12.296875 14.167969 10 14.167969Zm0 0" style="stroke:none;fill-rule:nonzero;fill:#000;fill-opacity:1"/>
    </svg>
  </div>
</template>

<script>
    export default {
      data() {
          return { }
      },
    }
</script>
