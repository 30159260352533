import Vue from 'vue'
import App from '../app.vue'
import store from '../src/store'
import VModal from 'vue-js-modal'
import BootstrapVue from 'bootstrap-vue'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
// import LanguageDetector from 'i18next-browser-languagedetector'
// TODO: realize language detector
import resources from '../../../config/locales/frontend/resources.js';

Vue.use(BootstrapVue);
Vue.use(VModal);

document.addEventListener('DOMContentLoaded', () => {
  let helloElement = document.getElementById('vueApp');
  const el = helloElement || document.body.appendChild(document.createElement('hello'));

  Vue.use(I18NextVue, { i18next });

  i18next.on("initialized", () => {
    new Vue({
      el,
      store,
      render: h => h(App)
    }).$mount("#app");
  });

  i18next.init({
    debug: false,
    fallbackLng: 'ru',
    resources,
  });
});
