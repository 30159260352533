<template>
  <div id="app" class="container-fluid">
    <div v-if="this !== null && this.jobs !== null">
    <searchForm></searchForm>
    <jobs-list></jobs-list>
  </div>
  <div v-if="this !== null && this.job !== null">
    <job-card></job-card>
  </div>
  <div v-if="this !== null && showJobVacancyForm">
    <jobVacancyForm/>
  </div>
  <error-occured></error-occured>
  <contact-recruiter></contact-recruiter>
  <additional-info-footer></additional-info-footer>
  </div>
</template>

<script>
  import JobCard from './src/components/JobCard.vue'
  import JobsList from './src/components/JobsList.vue'
  import searchForm from './searchForm.vue'
  import errorOccured from "./src/components/modals/errorOccured";
  import contactRecruiter from './src/components/modals/contactRecruiter';
  import additionalInfoFooter from './src/components/additionalInfoFooter.vue';
  import jobVacancyForm from './jobVacancyForm'
  import { mapState } from 'vuex';

  export default {
    components: {errorOccured, JobCard, JobsList, contactRecruiter, searchForm, additionalInfoFooter, jobVacancyForm},
      created: function () {
        let elements = document.getElementsByClassName("nojs-app");
          for (let item of elements) {
            item.className += " d-none"
           }
          this.$store.dispatch('jobsData/loadJobs');
          this.$store.dispatch('user/loadToken');
        },
      computed: {
        ...mapState({
          jobs: state => state.jobsData.jobs,
          job: state => state.jobsData.job
        }),
        showJobVacancyForm() {
          return window.location.pathname === '/vacancy_forms'
        },
      }
  }
</script>
