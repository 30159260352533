export default {
    translation: {
        wholeVacanciesList: 'Весь список вакансий',
        respondViaJooby: 'Откликнуться через Jooby.dev',
        respondViaTelegram: 'Откликнуться через Telegram',
        respondViaWebsite: 'Откликнуться через сайт',
        contact: 'Контакты',
        email: 'Email',
        telegram: 'Telegram',
        buttons: {
            example: 'Пример',
            submit: 'Отправить заявку',
        },
        channels: {
            python: 'Python Jobs',
            js: 'JS Jobs',
            golang: 'Golang Jobs',
            qa: 'QA Jobs',
            ruby: 'Ruby & Elixir Jobs',
            java: 'Java Jobs',
            devops: 'DevOps Jobs',
            android: 'Android Jobs',
            ios: 'iOS Jobs',
            rust: 'Rust Jobs',
        },
        workTypes: {
            remote: 'Удаленка',
            office: 'Офис',
            officeRemote: 'Офис/удаленка',
        },
        employmentType: {
            fullTime: 'Полный рабочий день',
            partTime: 'Подработка/неполный рабочий день',
        },
        salary: {
            paymentType: 'Тип выплаты',
            fork: 'Зарплатная вилка',
            gross: 'до вычета налогов',
            netto: 'на руки',
        },
        footer: {
            products: 'Продукты экосистемы',
            ITmarketAnalytics: 'Аналитика IT рынка',
            lookingForJob: 'Ищем работу вместе!',
            findOutAboutJooby: 'Узнайте о Jooby',
            aboutUs: 'О нас',
            termsOfUse: 'Условия использования',
        },
        modals: {
            thanksForResponse: 'Спасибо за отклик',
            employeeContact: 'Контакт работодателя:',
            sadOk: 'Okay :(',
            vacancyWasSended: 'Вакансия отправлена',
        },
        searchForm: {
            salary: {
                label: 'Вилка:',
                any: 'Любая',
                before1000Usd: '0 - 1000 USD',
                from1000to3000Usd: '1000 - 3000 USD',
                from2000to4000Usd: '2000 - 4000 USD',
                from3000to5000Usd: '3000 - 5000 USD',
                from5000Usd: '5000+ USD',
            },
            specializations: {
                label: 'Направления:',
                all: 'Все',
                ruby: 'Ruby engineer',
                go: 'Go engineer',
                js: 'Javascript engineer',
                python: 'Python engineer',
            },
            period: {
                label: 'Время:',
                all: 'За всё время',
                monthly: 'За месяц',
                weekly: 'За неделю',
            },
            justCall: 'только позови!',
            advancedSearch: 'Расширенный поиск',
            nothingFound: 'Мы ничего не нашли',
            letClickButton: 'Давайте нажмем на кнопку!',
            findBestJob: 'Найти лучшую работу!',
        },
        vacancyForm: {
            title: 'Форма для заполнения вакансии',
            infoFilling: 'Заполнение основной информации о вакансии',
            feedback: {
                textareaConstraints: 'Поле должно содержать от 10 до 2000 символов',
                tgInputConstraints: 'Поле должно содержать не менее 5 символов и состоять из a-z, 0-9, _',
            },
            selectChannel: 'Выберите направление',
            selectWorkType: 'Выберите тип работы',
            selectEmploymentType: 'Выберите тип занятости',
            companyDescription: {
                title: 'О компании',
                mainAdvice: 'Напишите немного про компанию: название, тип, чем занимается и с кем сотрудничает, а также укажите сайт.',
                cultureAdvice: 'Можете рассказать про культуру компании - это только приветствуется!',
                example: {
                    aboutCompany: 'Sophistica - небольшая аутсорсинговая компания, разрабатывающая программное обеспечение на заказ, 29 лет на рынке IT услуг. Работаем с такими известными клиентами как Deutsche Bahn, Merck, Commerzbank, Dusseldorf Airport, Wiener Linier, Swiss Accident Insurance, DHL, BASF и другие. Офисы находятся в Великобритании, Эстонии и Сербии.',
                    aboutTeam: 'В нашей команде 50 человек, всем от 20 до 40 лет, мы путешествуем, наслаждаемся жизнью и постоянно развиваемся профессионально. У нас нет дедлайнов, совещаний и регулярных отчетов и другой бюрократии. Мы ценим рабочий комфорт.',
                    website: 'https://sophistica.com/',
                },
            },
            jobDescription: {
                title: 'Кого ищем?',
                employeeAdvice: 'Напишите про уровень специалиста, его задачи и обязанности.',
                projectAdvice: 'Здесь также описывается проект, на котором будет работать специалист, и стек этого проекта',
                example: {
                    aboutEmployee: 'В связи с ростом задач мы ищем Senior Ruby on Rails Backend разработчика. У нас много интересных тасок и очень сильная команда, у которой вы можете учиться и относительно свободный график работы.',
                    aboutProject: 'Проект: разработка универсального программного обеспечения для управления уходом за пациентами на дому для американского заказчика.',
                    coreTasks: 'Основные задачи:',
                    task1: '• Разрабатывать новые фичи, решать реальные проблемы пользователей системы;',
                    task2: '• Снижать технический долг, находить места для рефакторинга и реализовывать его;',
                    task3: '• Предлагать новые классные идеи и продвигать их.',
                    stack: 'Стек: Ruby, Rails, Redis(SSDB), Clickhouse, Ansible, Capistrano, Gitlab, Code review, CI/CD, много тестов, нагруженный компонент на Golang (геораспределен).',
                },
            },
            requirements: {
                title: 'Требования к кандидату',
                mainAdvice: 'Опишите требования вакансии: точно те навыки, без которых кандидат не сможет жить в вашем проекте',
                example: {
                    req1: '• Ruby on Rails - опыт коммерческой разработки от 3 лет;',
                    req2: '• Уверенные знания computer science: структуры данных, сложность алгоритмов, работа с памятью и т.д.',
                    req3: '• Понимание принципов работы баз данных (MySQL/PostgreSQL);',
                    req4: '• Опыт написание автоматизированных тестов с использованием RSpec и Capybara;',
                    req5: '• Английский язык минимум B2.',
                    willBeAdvantage: 'Будет плюсом: опыт работы с одним из современных frontend фреймворков (React или Vue).',
                },
            },
            workingConditions: {
                title: 'Условия работы и бенефиты',
                mainAdvice: 'Здесь напишите про вид оформления (в штат, ИП/самозанятый, ГПХ), испытательный срок, график работы, месторасположение офисов, наличие страхования, образовательных курсов и конференций, премии, бонусы.',
                relocationAdvice: 'Также тут можно указать про возможность релокации и получение релокационной помощи',
                example: {
                    employment: '• Оформление - самозанятость, ИП;',
                    probationPeriod: '• Оплачиваемый испытательный срок 3 месяца;',
                    residence: 'Принимаем резидентов и нерезидентов РФ',
                    workingSchedule: '• Гибкий график, работаем примерно по московской таймзоне, самое важное - пересекаться со своей командой по рабочему времени;',
                    tracking: '• Отсутствие трекинг-систем. Работаем в Slack, Jira, GitLab;',
                    salaryReview: '• Ревью зарплаты раз в 6 месяцев;',
                    healthInsurance: '• ДМС (после испытательного срока);',
                    languageCourses: '• Курсы английского (после испытательного срока);',
                    device: '• Выдаём корпоративный MacBook Pro (по запросу);',
                    office: '• Офисы в Санкт-Петербурге и Таллинне;',
                    relocation: '• Возможность релокации в Таллинн, Эстония + релокационный пакет',
                },
            },
            salaryFork: {
                mainAdvice: 'Укажите сумму и валюту (RUB, EUR, USD)',
                example: 'Пример: 180 000 - 300 000 RUB',
            },
            contacts: {
                example: {
                    tg: 'Правильный формат Telegram: просто юзернейм (не ссылка), символ @ не требуется',
                },
            },
            placeholders: {
                email: 'Ваш email',
                tg: 'Ваш telegram',
            },
            supportDescription: {
                aboutFormReset: "* после отправки форма не очищается - так сделано, чтобы вы могли быстрее оформлять несколько заявок.",
                aboutAdditionalQuestions: 'Пожалуйста, свяжитесь с нами, если остались вопросы:',
                linkText: 'Тык!'
            }
        },
    },
};
