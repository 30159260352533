<template>
  <div>
    <job-full v-bind:job="this.job" v-if="this.job !== null"></job-full>
  </div>
</template>
<script>
  import JobFull from '../../jobFull.vue'
  import { mapState } from 'vuex';

  export default {
    components: {JobFull},
    computed: {
      ...mapState({
        job: state => state.jobsData.job
      })
    },
    methods: { },
    data() {
      return { }
    },
  }
</script>
