<template>
  <div v-bind:id=job_id class="card job-short " :class="this.job.attributes.featured ? 'featured' : ''" v-on:click="redirectToJob($event, job_link)">
    <a :href="`${job_link}`">
      <div class="card-header">
        <div class="job-info">
          <div class="publish-date">{{ this.job.attributes.publication_date }}</div>
          <h5 class="">{{ `${this.job.attributes.company_name} | ${this.job.attributes.profession_title}` }}</h5>
        </div>
      </div>
      <div class="card-body">
        <p>{{ `👨‍💻 ${this.job.attributes.work_type}` }}</p>
        <p>{{ `⏳ ${this.job.attributes.employment_type}` }}</p>
        <p v-if="this.show_salary">
          {{ `💰 ${this.salaryDivider(this.job.attributes.min_salary, this.job.attributes.max_salary)} ${this.job.attributes.type_of_currency}` }}
          {{` ${this.payment_type}` }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    props: ["job"],
    data: function() {
      let slug = this.job.attributes.slug;
      let job_link = slug;
      return {
        job_id: this.job.id,
        job_link: "/jobs/" + job_link,
        job_full_path: "https://jooby.dev/jobs/" + job_link,
        job_title: this.job.title,
        job_short_description: this.job.short_description,
        share_button_seen: false,
        show_salary: this.job.attributes.external_source === null || this.job.attributes.min_salary !== null || this.job.attributes.max_salary !== null,
        payment_type: this.job.attributes.payment_type,
      }
    },
    methods: {
      redirectToJob: function (e, url) {
        window.location.assign(url);
      },
      salaryDivider: function(min_salary, max_salary) {
        let min_salary_digits = parseInt(min_salary)
        let min_salary_digits_div = min_salary_digits.toLocaleString('en-US').replace(',', '.')
        let max_salary_digits = parseInt(max_salary)
        let max_salary_digits_div = max_salary_digits.toLocaleString('en-US').replace(',', '.')
        if ((isNaN(min_salary_digits_div)) || (isNaN(max_salary_digits_div))) {
          return '0 - 0'
        } else {
          return `${min_salary_digits_div} - ${max_salary_digits_div}`
        }
      }
    }
  }
</script>
