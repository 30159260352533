<template>
  <div class="no-results-icon">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50pt" height="40pt" viewBox="0 0 50 40" version="1.1">
      <g id="surface1">
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 40.949219 3.09375 C 40.011719 4.726562 39.25 6.082031 39.25 6.117188 C 39.25 6.210938 40.375 6.835938 40.429688 6.773438 C 40.523438 6.667969 43.8125 0.894531 43.8125 0.835938 C 43.8125 0.78125 43.773438 0.757812 43.054688 0.355469 L 42.648438 0.132812 Z M 40.949219 3.09375 "/>
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 31.59375 5.875 C 29.425781 8.300781 28.429688 9.367188 28.34375 9.367188 C 28.273438 9.367188 27.5 9.207031 26.625 9 C 25.75 8.800781 25.023438 8.636719 25.019531 8.644531 C 25.011719 8.648438 25.210938 9.460938 25.457031 10.445312 L 25.898438 12.238281 L 25.167969 13.050781 C 24.769531 13.5 24.4375 13.886719 24.4375 13.917969 C 24.445312 13.976562 33.957031 18.03125 34.085938 18.03125 C 34.164062 18.03125 38.8125 12.925781 38.8125 12.84375 C 38.8125 12.824219 38.054688 12.8125 37.125 12.8125 C 36.199219 12.8125 35.4375 12.800781 35.4375 12.78125 C 35.4375 12.761719 36.054688 11.664062 36.820312 10.335938 L 38.195312 7.929688 L 36.585938 5.355469 C 35.699219 3.9375 34.917969 2.695312 34.84375 2.582031 L 34.710938 2.382812 Z M 32.0625 8.75 C 32.4375 8.945312 32.59375 9.207031 32.59375 9.667969 C 32.59375 9.960938 32.5625 10.09375 32.460938 10.230469 C 32.007812 10.851562 31.179688 10.851562 30.730469 10.238281 C 30.65625 10.132812 30.59375 9.9375 30.582031 9.738281 C 30.550781 9.292969 30.738281 8.960938 31.125 8.761719 C 31.46875 8.59375 31.742188 8.585938 32.0625 8.75 Z M 32.0625 8.75 "/>
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 44.78125 6.570312 C 43.238281 7.460938 41.90625 8.226562 41.832031 8.269531 C 41.695312 8.34375 41.695312 8.34375 42.019531 8.925781 C 42.261719 9.355469 42.367188 9.5 42.449219 9.476562 C 42.554688 9.449219 48.238281 6.210938 48.335938 6.125 C 48.40625 6.0625 47.769531 4.9375 47.667969 4.945312 C 47.625 4.945312 46.332031 5.675781 44.78125 6.570312 Z M 44.78125 6.570312 "/>
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 43.0625 12.09375 L 43.0625 12.8125 L 50 12.8125 L 50 11.375 L 43.0625 11.375 Z M 43.0625 12.09375 "/>
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 1.164062 13.601562 C 0.738281 13.730469 0.242188 14.230469 0.09375 14.679688 C -0.117188 15.320312 0.105469 16.15625 0.59375 16.542969 C 0.699219 16.632812 1.699219 17.210938 2.8125 17.851562 L 4.84375 19 L 4.875 28.386719 L 4.90625 37.78125 L 5.105469 38.199219 C 5.367188 38.757812 5.636719 39.082031 6.082031 39.382812 C 7.199219 40.125 8.554688 40.011719 9.550781 39.105469 C 9.84375 38.832031 10.0625 38.492188 10.269531 37.980469 C 10.40625 37.664062 10.40625 37.570312 10.4375 33.757812 L 10.46875 29.851562 L 13.125 34.289062 C 15.261719 37.851562 15.851562 38.789062 16.125 39.0625 C 17.863281 40.792969 20.8125 39.554688 20.8125 37.101562 C 20.8125 36.851562 20.769531 36.492188 20.710938 36.304688 C 20.648438 36.09375 19.789062 34.601562 18.324219 32.15625 C 17.070312 30.054688 16 28.28125 15.949219 28.207031 L 15.863281 28.0625 L 26.375 28.0625 L 26.375 32.6875 C 26.375 35.84375 26.398438 37.414062 26.445312 37.636719 C 26.664062 38.664062 27.476562 39.519531 28.46875 39.78125 C 29.945312 40.164062 31.457031 39.269531 31.851562 37.789062 C 31.917969 37.53125 31.9375 36.542969 31.9375 32.75 L 31.945312 28.03125 L 32.195312 27.855469 C 32.636719 27.550781 32.625 27.695312 32.625 23.480469 L 32.625 19.707031 L 27.757812 17.636719 L 22.886719 15.5625 L 5.824219 15.5625 L 4.148438 14.613281 C 3.230469 14.09375 2.382812 13.632812 2.257812 13.585938 C 1.960938 13.480469 1.542969 13.488281 1.164062 13.601562 Z M 1.164062 13.601562 "/>
      </g>
    </svg>
  </div>  
</template>

<script>
    export default {
      data() {
          return { }
      },
    }
</script>

