<template>
  <modal name="contactRecruiter" class="contact-recruiter" height="auto" :adaptive=true  :scrollable=true @before-open="beforeOpen">
    <b-card-header class="mt-2">
      <b-card-title>👋 {{ $t('modals.thanksForResponse') }} 👋</b-card-title>
    </b-card-header>
    <b-card-body class="mt-3 mb-2">
      <div>{{ $t('modals.employeeContact') }}</div>
      <div class="fw-semibold">
        <b-link :href="url" target="_blank">{{ url }}</b-link>
      </div>
   </b-card-body>
  </modal>
</template>
<script>
  export default {
    methods: {
    beforeOpen(event) {
      this.url = event.params.url;
    }
    },
    data: () => {
      return {
        url: ''
      }
    }
  }
</script>
