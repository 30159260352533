<template>
  <footer class="footer">
    <section class="foot-section d-flex row-wrap">
      <aside class="left-aside"></aside>
        <div class="center-div d-flex row-wrap space-around">
          <div class="footer-item">
            <div class="footer-label"><span class="h5">{{ $t('footer.products') }}</span></div>
            <div class="divider"></div>
            <div>
              <a href="https://t.me/jooby_analytics" target="_blank" aria-label="Аналитика jooby.dev">{{ $t('footer.ITmarketAnalytics') }}</a>
            </div>
            <div>
              <a href="https://hiring.jooby.dev/" target="_blank" aria-label="Поиск работы вместе с jooby.dev">{{ $t('footer.lookingForJob') }}</a>
            </div>
          </div>
          <div class="footer-item none-first-item">
             <div class="footer-label"><span class="h5">{{ $t('footer.findOutAboutJooby') }}</span></div>
            <div class="divider"></div>
            <div>
              <a href="/contact_us_info" target="_blank" aria-label="Кратко о jooby.dev">{{ $t('footer.aboutUs') }}</a>
            </div>
            <div>
              <a href="/privacy_info" target="_blank" aria-label="Аналитика рынка от jooby.dev">{{ $t('footer.termsOfUse') }}</a>
            </div>
          </div>
        </div>
        <aside class="right-aside">
          <div />
        </aside>
    </section>
  </footer>
</template>
<script>
    export default {}
</script>
