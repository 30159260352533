<template v-if="ok">
  <div>
    <div class="search_container">
      <div class="search-settings" v-bind:class="{'hidden': button_active_state }">
        <div class="search-settings-selectors">
          <div class="salaries-container">
            <label for="salaries">{{ $t('searchForm.salary.label') }}</label>
            <select name="salaries" id="salaries-selector">
              <option value="all">{{ $t('searchForm.salary.any') }}</option>
              <option value="0k-1k">{{ $t('searchForm.salary.before1000Usd') }}</option>
              <option value="1k-3k">{{ $t('searchForm.salary.from1000to3000Usd') }}</option>
              <option value="2k-4k">{{ $t('searchForm.salary.from2000to4000Usd') }}</option>
              <option value="3k-5k">{{ $t('searchForm.salary.from3000to5000Usd') }}</option>
              <option value="5k-plus">{{ $t('searchForm.salary.from5000Usd') }}</option>
            </select>
          </div>
          <div class="technology-container">
            <label for="technology">{{ $t('searchForm.specializations.label') }}</label>
            <select name="technology" id="technology-selector">
              <option value="all">{{ $t('searchForm.specializations.all') }}</option>
              <option value="ruby">{{ $t('searchForm.specializations.ruby') }}</option>
              <option value="go">{{ $t('searchForm.specializations.go') }}</option>
              <option value="js">{{ $t('searchForm.specializations.js') }}</option>
              <option value="python">{{ $t('searchForm.specializations.python') }}</option>
            </select>
          </div>
          <div class="datetime-container">
            <label for="time">{{ $t('searchForm.period.label') }}</label>
            <select name="time" id="time-selector">
              <option value="all">{{ $t('searchForm.period.all') }}</option>
              <option value="month">{{ $t('searchForm.period.monthly') }}</option>
              <option value="week">{{ $t('searchForm.period.weekly') }}</option>
            </select>
          </div>
        </div>
        <button
          v-on:click="redirectToSearchUrl($event)"
          class="btn btn-yellow btn-dark extend_search_jobs_btn"
        >
          {{ $t('searchForm.advancedSearch') }}!
        </button>
      </div>  
      <div class="search_input_container">
        <joobyLogo/>
        <input autofocus class="search_input" :placeholder="$t('searchForm.justCall')" :value='query' @input='evt=>query=evt.target.value' v-on:keyup="compositionUpdate"/>
        <div v-on:click="button_active_state = !button_active_state" :title="$t('searchForm.advancedSearch')">
          <settingsSearch/>
        </div>
      </div>
        <div class="search-results">
          <div v-if="this.query.length > 0 && inputPresent === true">
            <div v-for="job in searchResults" :key="job.slug" class="search-card" v-on:click="redirectToJob($event, job.attributes.slug)">
              <div class="search-stats">
                {{ job.attributes.company_name}} | {{job.attributes.profession_title}} 💰{{salaryDivider(job.attributes.min_salary, job.attributes.max_salary)}} {{job.attributes.type_of_currency}}
              </div>  
              <div v-if="job.attributes.search_description.length > 0">
                <div class="search-in"> 
                  🔎 {{ job.attributes.search_description }} 
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.query.length > 0 && this.inputPresent === true && this.searchResults.length === 0" >
            <div class="no-found-results">
              <div class="no-found-results-text">
                {{ $t('searchForm.nothingFound') }}  😔
              </div>
              <noResultsIcon/>
              <div class="no-found-results-text">
                {{ $t('searchForm.letClickButton') }}
              </div>
            </div>  
          </div>
          <div v-if="this.query.length > 0" >
            <button v-if="this.searchVariantShow"
              v-on:click="redirectToSearchUrl($event)"
              class="btn btn-yellow btn-dark search_jobs_btn"
            >
              {{ $t('searchForm.findBestJob') }}
            </button>
          </div>  
        </div>  
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import joobyLogo from './src/components/svg/joobyLogo.vue';
  import settingsSearch from './src/components/svg/settingsSearch.vue';
  import contactRecruiter from './src/components/modals/contactRecruiter';
  import noResultsIcon from './src/components/svg/noResultsIcon.vue';
  import { LodashReplacement } from './src/utils/lodash_replacement.js';

  export default {
    components: { joobyLogo, settingsSearch, noResultsIcon, contactRecruiter },
    search_input: '.search_input',
    data: () => ({
      button_active_state: true,
      query: "",
      searchResults: [],
      inputPresent: false,
      search_param: null,
    }),
    mounted: function() {
      this.setSearchParams();
    },
    computed: {
      searchVariantShow() {
        return this.inputPresent
      }
    },
    methods: {
      showSettingsSearchModal: function (e) {
        {{ console.log('showSettingsSearchModal'); }}
      },
      checkPathOnJobs: function() {
        return window.location.pathname != "/jobs/"
      },
      compositionUpdate: function() {
        if (!this.query || this.query === '') {
          this.searchResults = [];
          this.inputPresent = true;
          return;
        }
        this.debounceName();
        this.inputPresent = false;
      },
      checkName() {
        axios
          .get("/search_jobs", {
            params: {
              search: this.query
            }
          })
          .then(res => {
            if (this.inputPresent) {
              this.inputPresent = false;
              return;
            };
            this.searchResults = res.data.data;
            this.inputPresent = true
          })
          .catch(err => {
            console.log(err);
          });
      },
      redirectToJob: function (e, url) {
        window.location.assign("/jobs/" + url);
      },
      redirectToSearchUrl: function (e) {
        let keyWord = document.querySelector("input[class=search_input]").value
        let timeStamp = document.querySelector("select[id=time-selector]").value
        let salary = document.querySelector("select[id=salaries-selector]").value
        let technology = document.querySelector("select[id=technology-selector]").value
        window.location.assign(`/jobs/?search=${keyWord}&salary=${salary}&timeStamp=${timeStamp}&technology=${technology}`);
      },
      setSearchParams: function() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('search')) {
           let searchParam = urlParams.get('search')
           document.getElementsByClassName('search_input')[0].value = searchParam;
        }
      },
      salaryDivider: function(min_salary, max_salary) {
        let min_salary_digits = parseInt(min_salary)
        let min_salary_digits_div = min_salary_digits.toLocaleString('en-US').replace(',', '.')
        let max_salary_digits = parseInt(max_salary)
        let max_salary_digits_div = max_salary_digits.toLocaleString('en-US').replace(',', '.')
        if ((isNaN(min_salary_digits_div)) || (isNaN(max_salary_digits_div))) {
          return '0 - 0'
        } else {
          return `${min_salary_digits_div} - ${max_salary_digits_div}`
        }
      }
    },
    created() {
      this.debounceName = LodashReplacement.Debounce(this.checkName, 800);
    },
  };
</script>

<style scoped></style>
