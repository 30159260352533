<template>
  <div class="job-full-container">
    <searchForm></searchForm>
    <div v-bind:id=job_id class="card job-full">
      <div class="card-header">
        <h5 class="">{{ this.job.attributes.company_name }}</h5>
        <p>{{ this.job.attributes.publication_date }}</p>
      </div>
      <div class="card-body">
        <p v-html="this.job.attributes.description"></p>
      </div>
      <div class="card-footer">
        <a class="btn btn-yellow btn-dark" v-if="this.authorized" v-on:click="this.applyWithUs">{{ $t('respondViaJooby') }}</a>
        <a class="btn btn-yellow btn-dark telegram-contact" v-if="!this.authorized" v-on:click="this.contactRecruiter">{{ $t('respondViaTelegram') }}</a>
        <a class="btn btn-yellow btn-dark external-site-contact" v-if="!this.authorized && this.job.attributes.external_source !== undefined && this.job.attributes.external_source !== null" v-on:click="this.contactExternalRecruiter">{{ $t('respondViaWebsite') }}</a>
        </div>
      </div>
      <sign-up-and-apply :job_id=this.job.id></sign-up-and-apply>
  </div>  
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import searchForm from './searchForm.vue'
import applicationApi from './src/api/application';

export default {
  components: {searchForm},
  props: ["job"],
  methods: {
    applyWithUs: function (e) {
      if (this.authorized) {
        applicationApi.create(this.job.id).then(x => {
        }).catch(function (error) {
          this.$modal.show('dialog', {
            title: 'Alert!',
            text: error.message
          })
        });
      } else {
      }
    },
    contactRecruiter: function(e) {
      e.preventDefault();
      let self = this;
      this.$store.dispatch('user/fetchContact', {job_id: this.job.id}).then(x => {
        self.$modal.show('contactRecruiter', {
          url: x.data.url
        })
      })
    },
    contactExternalRecruiter: function(e) {
      e.preventDefault();
      let self = this;
      this.$store.dispatch('user/fetchExternalContact', {job_id: this.job.id}).then(x => {
        self.$modal.show('contactRecruiter', {
          url: x.data.url
        })
      })
    }
  },
  data: function() {
    return {
      job_id: this.job.id
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters('user', {
      authorized: 'authorized'
    })
  }

}
</script>

<style scoped>
p {
  font-size: small;
}
</style>
